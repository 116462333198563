import React, {FunctionComponent} from 'react';
import {Translate} from '../../components/translations';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';

const PrivacyPolicy: FunctionComponent = () => (
  <Layout>
    <div className="content-wrapper">
      <PageHero title="PRIVACY_POLICY_PAGE_TITLE" subtitle="PRIVACY_POLICY_PAGE_SUBTITLE" />
      <Section>
        <div className="col-lg-12">
          <Translate name="PRIVACY_POLICY_PAGE_CONTENT_INTRO_HEXOMETER" />
          <Translate name="PRIVACY_POLICY_PAGE_CONTENT_NEW" />
        </div>
      </Section>
    </div>
  </Layout>
);

export default PrivacyPolicy;
